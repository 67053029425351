<template>
	<div>
		<el-page-header @back="$router.back()" content="订单列表" class="margin-bottom margin-top-xs"></el-page-header>
		<div class="flex justify-between">
			<el-form :inline="true" :model="searchForm">
				<el-form-item label="用户昵称">
					<el-input v-model="searchForm.search" placeholder="用户昵称" @submit.native.prevent></el-input>
				</el-form-item>
				<el-form-item label="订单状态">
					<el-select v-model="searchForm.status" placeholder="请选择">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="loadData(1)">查询</el-button>
					<el-button type="primary" @click="exportThis()">导出数据</el-button>
				</el-form-item>
			</el-form>
		</div>

		<el-table :data="tableData" v-loading="loading" max-height="9999">
			<el-table-column type="index" width="50"></el-table-column>
			<el-table-column prop="nickname" label="用户昵称"></el-table-column>
			<el-table-column prop="title" label="活动名称"></el-table-column>
			<el-table-column prop="order_no" label="订单编号">
			</el-table-column>
			<el-table-column prop="price" label="单价"></el-table-column>
			<el-table-column label="支付状态">
				<template slot-scope="scope">
					<div v-if="scope.row.pay_status == 1">待支付</div>
					<div v-if="scope.row.pay_status == 2">已支付</div>
					<div v-if="scope.row.pay_status == '-1'">取消支付</div>
				</template>
			</el-table-column>
			<el-table-column prop="pay_money" label="付款总金额"></el-table-column>
			<el-table-column prop="pay_time" label="付款时间"></el-table-column>
			<el-table-column label="订单类型">
				<template slot-scope="scope">
					<div v-if="scope.row.order_type == 1">单独购买</div>
					<div v-if="scope.row.order_type == 2">活动(拼团)购买</div>
				</template>
			</el-table-column>
			<el-table-column label="订单状态">
				<template slot-scope="scope">
					<div v-if="scope.row.type == 1">待付款</div>
					<div v-if="scope.row.type == 2">进行中</div>
					<div v-if="scope.row.type == 3">待使用</div>
					<div v-if="scope.row.type == 4">已使用</div>
				</template>
			</el-table-column>
			<el-table-column label="核销状态">
				<template slot-scope="scope">
					<div v-if="scope.row.check_status == 2">已核销</div>
					<div v-else>待核销</div>
				</template>
			</el-table-column>
			<el-table-column prop="check_time" label="核销时间" width="200"></el-table-column>
			<el-table-column label="活动状态">
				<template slot-scope="scope">
					<div v-if="scope.row.promotion_status == 1">进行中</div>
					<div v-if="scope.row.promotion_status == 2">活动结束</div>
					<div v-if="scope.row.promotion_status == '-1'">活动失败</div>
				</template>
			</el-table-column>
			<el-table-column fixed="right" label="操作" width="300">
				<template slot-scope="scope">
					<el-button size="mini" type="text" @click="$router.push(`/marketingCenter/group/orderDetail/${scope.row.id}`)">查看详情</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="text-center margin-top">
			<el-pagination @current-change="pageChange" :current-page="searchForm.page" :page-sizes="vuex_page_sizes"
				:page-size="searchForm.page_size" layout="total, sizes, prev, pager, next, jumper" :total="total"
				@size-change="pageSizeChange">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				loading: false,
				tableData: [],
				options:[],
				searchForm: {
					search: '',
					page: 1,
					page_size: 10
				},
				total: 0
			};
		},
		mounted() {
			this.loadData();
		},
		methods: {
			toEdit(id){
				if(id){
					this.$router.push(`/marketingCenter/group/edit/${id}`)
				}else{
					this.$router.push(`/marketingCenter/group/edit`)
				}
			},
			exportThis(){
				this.$api.marketing.exportOrderList({
					search:this.searchForm.search
				}).then(res => {
					console.log(res)
					let a = document.createElement('a');
					//ArrayBuffer 转为 Blob
					let blob = new Blob([res], {
						type: "application/vnd.ms-excel"
					});
					let objectUrl = URL.createObjectURL(blob);
					console.log(objectUrl)
					a.setAttribute("href", objectUrl);
					a.setAttribute("download", '订单列表.xls');
					a.click();
				})
			},
			loadData(num) {
				console.log(num)
				if (num == 1){
					this.searchForm.page = num
				}
				this.loading = true;
				this.$api.marketing.getOrderList(this.searchForm).then().then(res => {
					console.log(res)
					this.loading = false;
					this.tableData = res.data.data;
					this.total = res.data.total;
				});
			},
			pageSizeChange(size) {
				this.searchForm.page_size = size;
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.loadData()
			},
		}
	};
</script>
<style scoped lang="scss">
	.cover {
		width: 40px;
		height: 40px;
		border-radius: 5px;
		margin-right: 5px;
	}
</style>
